/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { MIN_TABLET_MEDIA_QUERY } from "typography-breakpoint-constants";

import Header from "./header";
import Footer from "./footer";
import "../css/globals.css";

const useStyles = createUseStyles({
    globalWrapper: {
        margin: "0em auto",
        maxWidth: "50em",
    },
    main: {
        padding: "1em",
    },
    [MIN_TABLET_MEDIA_QUERY]: {
        main: {
            padding: "2em",
        },
    },
});

const Layout = ({ children }) => {
    const styles = useStyles();

    return (
        <div className={styles.globalWrapper}>
            <Header/>
            <main className={styles.main}>{children}</main>
            <Footer />
        </div>
    );

};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
