import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = createUseStyles({
    iconLink: {
        "&:is(span)": {
            color: "var(--solid-grey)",
            boxShadow: "0 2px 0 var(--solid-grey)",
        },
        "& > svg:not(:only-child)": {
            "&:first-child": {
                marginRight: ".5rem",
            },
            "&:last-child": {
                marginLeft: ".5rem",
            },
        },
    },
});

export const IconLink = ({ href, icon, text, side }) => {
    const classes = useStyles();
    const content = (
        <>
            {side === "left" && icon && <FontAwesomeIcon icon={icon} />}
            {text && <span>{text}</span>}
            {side === "right" && icon && <FontAwesomeIcon icon={icon} />}
        </>
    );
    if (href) {
        return (
            <a className={classes.iconLink} href={href} target="_blank" rel="noreferrer">
                {content}
            </a>
        );
    } else {
        return (
            <span className={classes.iconLink}>
                {content}
            </span>
        );
    }

};

IconLink.propTypes = {
    href: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    text: PropTypes.string,
    side: PropTypes.oneOf(["left", "right"]),
};

IconLink.defaultProps = {
    side: "left",
};

export default IconLink;
