import React from "react";
import { createUseStyles } from "react-jss";
import Contacts from "./contacts";

const useStyles = createUseStyles({
    footer: {
        margin: "1em 0",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        "& > section": {
            fontSize: "1.1rem",
        },
    },
});

const Footer = () => {
    const styles = useStyles();

    return (
        <footer className={styles.footer} >
            <span>
                © {new Date().getFullYear()} Tiago Fragoso •
            </span>
            <Contacts />
        </footer>
    );
};

export default Footer;
