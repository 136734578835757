import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { createUseStyles } from "react-jss";

import { MIN_TABLET_MEDIA_QUERY } from "typography-breakpoint-constants";

import ThemeToggle from "./themeToggle";

const useStyles = createUseStyles({
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
        borderBottom: "1px solid var(--body-color)",
        color: "var(--header-color)",
        padding: "0.2em 1em 0.5em 1em",
        "& a": {
            color: "var(--header-color)",
            boxShadow: "none",
        },
    },
    nav: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-end",
    },
    navLink: {
        fontWeight: "inherit",
        margin: 0,
        marginLeft: "1em",
        color: "inherit",
        "&:hover": {
            color: "var(--accent-color)",
        },
        "&.disabled": {
            color: "var(--solid-grey)",
            cursor: "default",
        },
    },
    title: {
        fontWeight: "500",
        margin: 0,
    },
    [MIN_TABLET_MEDIA_QUERY]: {
        header: {
            padding: "1em",
        },
        navLink: {
            marginLeft: "1.5em",
        },
    },
});

const StyledNavLink = ({ to, label, disabled, external, ...props }) => {
    const styles = useStyles();

    if (external) {
        return (
            <a className={`${styles.navLink} ${disabled ? "disabled" : ""}`} href={to} target="_blank" rel="noreferrer">
                { disabled ? <strike>{label}</strike> : label }
            </a>
        );
    }
    /* JSS not supported here, so inline-styling it is */
    return (
        <Link
            to={disabled ? null : to}
            className={`${styles.navLink} ${disabled ? "disabled" : ""}`}
            activeStyle={{
                fontWeight: "bold",
                color: "var(--accent-color)",
            }}
            partiallyActive={true}
            {...props}
        >
            { disabled ? <strike>{label}</strike> : label }
        </Link>
    );
};

StyledNavLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
};

const Header = () => {
    const styles = useStyles();

    return (
        <header className={styles.header}>
            <Link to="/">
                <h2 className={styles.title}>
                    <b>t</b>iago <b>f</b>ragoso
                </h2>
            </Link>
            <nav className={styles.nav}>
                <StyledNavLink to="/blog" label="blog" />
                <StyledNavLink to="/projects" label="projects" />
                <StyledNavLink
                    to="https://drive.google.com/file/d/18V3sKGYqFDQyWPGu9vEYcRrHolxLbEHP/view?usp=sharing"
                    label="resume" external
                />
                <ThemeToggle />
            </nav>
        </header>
    );
};

export default Header;
