import React from "react";
import { createUseStyles } from "react-jss";
import { ThemeToggler } from "gatsby-plugin-dark-mode";

import { faLightbulb as faLightbuildOutSolid } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb as faLightbuildOutline } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = createUseStyles({
    hidden: {
        display: "none",
    },
    themeToggler: {
        cursor: "pointer",
        fontSize: "1.5em",
        transition: "all 0.3s ease-in-out",
        marginLeft: "1em",
        color: "var(--solid-grey)",
        "& > svg": {
            verticalAlign: "-0.3em",
        },
    },
    lit: {
        color: "var(--yellow)",
        "&:hover": {
            color: "var(--solid-grey)",
        },
    },
    dim: {
        "&:hover": {
            color: "var(--yellow)",
        },
    },
});

export const ThemeToggle = () => {
    const classes = useStyles();
    return (
        <ThemeToggler>
            {({ theme, toggleTheme }) => {
                if (theme === null || theme === undefined) {
                    return null;
                }
                return (
                    <label className={classes.themeToggler}>
                        <input
                            className={classes.hidden}
                            type="checkbox"
                            onChange={(e) => toggleTheme(e.target.checked ? "dark" : "light")}
                            checked={theme === "dark"}
                        />
                        {
                            theme === "dark" ?
                                <FontAwesomeIcon className={classes.lit} icon={faLightbuildOutSolid} /> :
                                <FontAwesomeIcon className={classes.dim} icon={faLightbuildOutline} />
                        }
                    </label>
                );
            }}
        </ThemeToggler>
    );
};

export default ThemeToggle;
