import React from "react";
import { createUseStyles } from "react-jss";

import IconLink from "./iconLink";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";

const useStyles = createUseStyles({
    contacts: {
        display: "flex",
        justifyContent: "center",
        fontSize: "1.5em",
        "& > *": {
            margin: "0 .5em",
        },
    },
});

export const Contacts = () => {
    const classes = useStyles();
    return (
        <section className={classes.contacts}>
            <IconLink href="mailto:tiagofragoso@outlook.com" icon={faEnvelope} />
            <IconLink href="https://linkedin.com/in/tiagojfragoso" icon={faLinkedinIn} />
            <IconLink href="https://github.com/tiagofragoso" icon={faGithub} />
        </section>
    );
};

export default Contacts;
